/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type  function
   *  @date  8/11/2013
   *  @since 4.3.0
   *
   *  @param $marker (jQuery element)
   *  @param map (Google Map object)
   *  @return  n/a
   */

  function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: "/wp-content/themes/infinity/assets/images/map-marker.png",
      open: true
    });

    // add to array
    map.markers.push(marker);

    var prev_infowindow = false;

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });
      liTag = $(".store-list").find("[data-lat='" + $marker.attr('data-lat') + "']");
      // console.log(liTag);
      // show info window when marker is clicked
      $(liTag).click(function() {
        if (prev_infowindow) {
          prev_infowindow.close();
        }
        // $('.location-link').removeClass('active');
        // $(this).toggleClass('active');
        // $(this).text("Shown on map");

        var target = $(this).nextAll('.location-link');
        $(".store-link").not(target).removeClass('active');
        $(this).addClass('active');
        // $(this).find('span').text("shown on map");

        var offset = 260; //Offset of 20px

        $('html, body').animate({
          scrollTop: $("#storemap").offset().top - offset
        }, 2000);


        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
        map.setZoom(16);
        map.setCenter(latlng);
        prev_infowindow = infowindow;
      });

      google.maps.event.addListener(marker, 'click', function() {
        if (prev_infowindow) {
          prev_infowindow.close();
        }
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
        prev_infowindow = infowindow;
      });

      // close info window when map is clicked
      google.maps.event.addListener(map, 'click', function(event) {
        if (infowindow) {
          infowindow.close();
        }
      });

      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open(map, marker);

      });
    }

  }

  /*
   *  center_map 
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type  function
   *  @date  8/11/2013
   *  @since 4.3.0
   *
   *  @param map (Google Map object)
   *  @return  n/a
   */

  function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {

      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);

    });

    // only 1 marker?
    if (map.markers.length === 1) {
      // set center of map

      map.setCenter(bounds.getCenter());
      map.setZoom(14);

    } else {
      // fit to bounds
      map.fitBounds(bounds);
      // map.setZoom( 14 ); // Change the zoom value as required
    }

  }

  function new_map($el) {

    // var
    var $markers = $el.find(".marker");

    var styles = [{
      "featureType": "all",
      "elementType": "all",
      "stylers": [{
          "hue": "#ff6800"
        },
        {
          "saturation": "20"
        },
        {
          "lightness": "-8"
        },
        {
          "gamma": "1.00"
        },
        {
          "weight": "1.12"
        }
      ]
    }];

    // vars
    var args = {
      zoom: 15,
      center: new google.maps.LatLng(-26.657629, 153.081856),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: styles
    };


    // create map           
    var map = new google.maps.Map($el[0], args);


    // add a markers reference
    map.markers = [];


    // add markers
    $markers.each(function() {

      add_marker($(this), map);

      google.maps.event.trigger(map.markers[0], 'click');

    });


    // center map
    center_map(map);


    // return
    return map;

  }


  $.fn.menumaker = function(options) {
    var cssmenu = $(this),
      settings = $.extend({
        format: "dropdown",
        sticky: false
      }, options);
    return this.each(function() {
      $(this).find(".button").on('click', function() {
        $(this).toggleClass('menu-opened');
        var mainmenu = $(this).next('ul');
        if (mainmenu.hasClass('open')) {
          mainmenu.slideToggle().removeClass('open');
        } else {
          mainmenu.slideToggle().addClass('open');
          if (settings.format === "dropdown") {
            mainmenu.find('ul').show();
          }
        }
      });
      cssmenu.find('li ul').parent().addClass('has-sub');
      multiTg = function() {
        cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
        cssmenu.find('.submenu-button').on('click', function() {
          $(this).toggleClass('submenu-opened');
          if ($(this).siblings('ul').hasClass('open')) {
            $(this).siblings('ul').removeClass('open').slideToggle();
          } else {
            $(this).siblings('ul').addClass('open').slideToggle();
          }
        });
      };
      if (settings.format === 'multitoggle') multiTg();
      else cssmenu.addClass('dropdown');
      if (settings.sticky === true) cssmenu.css('position', 'fixed');
      resizeFix = function() {
        var mediasize = 1000;
        if ($(window).width() > mediasize) {
          cssmenu.find('ul').show();
        }
        if ($(window).width() <= mediasize) {
          cssmenu.find('ul').hide().removeClass('open');
        }
      };
      resizeFix();
      return $(window).on('resize', resizeFix);
    });
  };



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $(".block-locations .card-inner").matchHeight();

        $('.acf-map').each(function() {
          // create map
          map = new_map($(this));
        });

        $(document).ready(function() {
          $(".nav-primary").menumaker({
            format: "multitoggle"
          });

          // var disableddates = ["26-01-2016", "25-12-2016"];

          // function DisableSpecificDates(date) {
          //     var string = jQuery.datepicker.formatDate('dd-mm-yy', date);
          //     return [disableddates.indexOf(string) == -1];
          // }
          // $('#inf_custom_TrialClassDate').datepicker({
          //   minDate: 0, maxDate: "+1M +10D",
          //   dateFormat: 'dd/mm/yy',
          //   beforeShowDay: DisableSpecificDates
          // });

        });

        $('#locations_select').on('change', function() {
          var url = $(this).val(); // get selected value
          if (url) { // require a URL
            window.location = url; // redirect
          }
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Locations
    'locations': {
      init: function() {


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
